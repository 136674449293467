<template>
  <div class="products-item">
    <div class="products-item__header">
      <div class="products-item__status grey">
        <span>Комплектуется</span>
      </div>
      <div class="products-item__info">
        <div class="products-item__info-item">
          <div class="products-item__info-item-title">
            Номер заказа
          </div>
          <div class="products-item__info-item-value">
            24205245245425
          </div>
        </div>
        <div class="products-item__info-item">
          <div class="products-item__info-item-title">
            Кол-во позиций
          </div>
          <div class="products-item__info-item-value">
            15
          </div>
        </div>
        <div class="products-item__info-item">
          <div class="products-item__info-item-title">
            Номер отгрузки
          </div>
          <div class="products-item__info-item-value">
            00013256
          </div>
        </div>
        <div class="products-item__info-item">
          <div class="products-item__info-item-title">
            Дата заказа
          </div>
          <div class="products-item__info-item-value">
            23/4/2021
          </div>
        </div>
        <div class="products-item__info-item">
          <div class="products-item__info-item-title">
            Сумма заказа
          </div>
          <div class="products-item__info-item-value">
            989 ₴
          </div>
        </div>
        <div class="products-item__info-item">
          <div class="products-item__info-item-title">
            Сумма отгрузки
          </div>
          <div class="products-item__info-item-value">
            100 ₴
          </div>
        </div>
      </div>
      <div class="products-item__controls" v-bind:class = "(isVisible)?'hidden':''" >
        <a href="" class="products-item__link">
          <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.336 7.556V6.464H3.472V3.02H4.62V6.464H7.742V7.556H4.62V11H3.472V7.556H0.336ZM8.61208 11.588L12.1681 0.584H13.3721L9.81608 11.588H8.61208ZM14.8843 7.612V6.408H18.9723V7.612H14.8843Z" fill="white"/>
          </svg>
        </a>
        <a href="" class="products-item__link">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M2.0378 13.1635H1.0458L1.74727 12.462C2.12549 12.0838 2.36167 11.5918 2.42517 11.0582C1.43821 10.4105 0.716508 9.55837 0.330179 8.58114C-0.0558761 7.60465 -0.10439 6.52971 0.189898 5.47249C0.542998 4.20392 1.37118 3.04768 2.52184 2.2167C3.77224 1.31373 5.32885 0.836426 7.0234 0.836426C9.15809 0.836426 10.9574 1.44974 12.2267 2.61001C13.3702 3.65532 14 5.06528 14 6.58022C14 7.31623 13.8501 8.03237 13.5546 8.70877C13.2487 9.4087 12.8009 10.0319 12.2235 10.5611C10.9524 11.7259 9.15425 12.3417 7.02337 12.3417C6.23233 12.3417 5.40689 12.2361 4.66992 12.0423C3.97235 12.7579 3.02709 13.1635 2.0378 13.1635ZM7.0234 1.65823C3.62555 1.65823 1.54061 3.6846 0.981594 5.69286C0.45334 7.59068 1.23205 9.38393 3.06467 10.4899L3.26944 10.6134L3.26314 10.8525C3.25064 11.3257 3.13784 11.7815 2.93592 12.1935C3.42761 12.0271 3.87376 11.7255 4.22314 11.3127L4.39687 11.1075L4.6545 11.1845C5.37749 11.4008 6.21877 11.5199 7.0234 11.5199C11.2522 11.5199 13.1782 8.95931 13.1782 6.58022C13.1782 5.29887 12.6434 4.10429 11.6722 3.21658C10.557 2.19711 8.9494 1.65823 7.0234 1.65823Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="14" height="14" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </a>
        <a href="" class="products-item__link">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M10.5228 4.94096C10.5037 4.94096 0.987128 4.94096 0.987128 4.94096L2.99213 2.69366C3.12215 2.54792 3.10941 2.32439 2.96367 2.19437C2.81798 2.06438 2.59439 2.07706 2.46437 2.22283L0.206274 4.75376C-0.0687762 5.06215 -0.0687489 5.5271 0.206301 5.8354L2.4644 8.36636C2.53429 8.4447 2.63111 8.48457 2.72837 8.48457C2.81216 8.48457 2.89624 8.45495 2.96369 8.39482C3.10944 8.2648 3.12215 8.04127 2.99216 7.89553L0.987155 5.6482C0.987155 5.6482 10.5037 5.6482 10.5228 5.6482C12.0502 5.6482 13.2927 6.89081 13.2927 8.41812C13.2927 9.94543 12.0501 11.188 10.5228 11.188H8.83898C8.64367 11.188 8.48534 11.3464 8.48534 11.5417C8.48534 11.737 8.64367 11.8953 8.83898 11.8953H10.5228C12.4401 11.8953 14 10.3355 14 8.41812C14 6.50081 12.4401 4.94096 10.5228 4.94096Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="14" height="14" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </a>
        <a href="" class="products-item__link">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.58621 11.4958C6.58631 11.4959 6.58642 11.496 6.58653 11.4961C6.60001 11.5096 6.61421 11.5223 6.62894 11.5344C6.6357 11.54 6.64283 11.5448 6.64978 11.55C6.65807 11.5562 6.66619 11.5627 6.6748 11.5684C6.68311 11.574 6.69173 11.5788 6.70026 11.5839C6.70808 11.5886 6.71571 11.5934 6.72374 11.5977C6.73244 11.6024 6.74138 11.6063 6.75027 11.6105C6.75864 11.6145 6.76689 11.6187 6.77548 11.6222C6.78409 11.6258 6.7929 11.6287 6.80165 11.6318C6.81086 11.6351 6.81994 11.6386 6.82935 11.6415C6.8381 11.6441 6.84698 11.6461 6.85584 11.6483C6.86544 11.6507 6.87493 11.6534 6.88472 11.6553C6.89497 11.6574 6.90531 11.6586 6.91562 11.66C6.92412 11.6612 6.93251 11.6629 6.94113 11.6637C6.97941 11.6675 7.01799 11.6675 7.05627 11.6637C7.06489 11.6629 7.07328 11.6613 7.08178 11.66C7.09209 11.6586 7.10243 11.6574 7.11268 11.6553C7.12247 11.6534 7.13196 11.6507 7.14156 11.6483C7.15039 11.6461 7.1593 11.6441 7.16805 11.6415C7.17746 11.6386 7.18656 11.6351 7.19575 11.6318C7.2045 11.6286 7.21331 11.6258 7.22192 11.6222C7.23051 11.6187 7.23876 11.6145 7.24713 11.6105C7.25602 11.6063 7.26496 11.6024 7.27365 11.5977C7.28169 11.5934 7.28932 11.5885 7.29714 11.5839C7.30567 11.5788 7.31429 11.574 7.3226 11.5684C7.33121 11.5627 7.33933 11.5562 7.34762 11.55C7.35456 11.5448 7.3617 11.54 7.36846 11.5344C7.38319 11.5223 7.39739 11.5096 7.41087 11.4961C7.41098 11.496 7.41108 11.4959 7.41119 11.4958L11.4945 7.41248C11.7223 7.18468 11.7223 6.81532 11.4945 6.58752C11.2667 6.35972 10.8974 6.35972 10.6696 6.58752L7.58201 9.67504V0.583324C7.58201 0.26116 7.32085 0 6.99869 0C6.67652 0 6.41536 0.26116 6.41536 0.583324V9.67504L3.32784 6.58752C3.10004 6.35972 2.73068 6.35972 2.50288 6.58752C2.27508 6.81532 2.27508 7.18468 2.50288 7.41248L6.58621 11.4958Z" fill="white"/>
            <path d="M11.082 12.8334H2.91536C2.59319 12.8334 2.33203 13.0945 2.33203 13.4167C2.33203 13.7389 2.59319 14 2.91536 14H11.082C11.4042 14 11.6654 13.7389 11.6654 13.4167C11.6654 13.0946 11.4042 12.8334 11.082 12.8334Z" fill="white"/>
          </svg>
        </a>
        <button class="products-item__arr">
          <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.3293 5.87341L11.8643 0.734858C12.0455 0.566499 12.0452 0.293923 11.8634 0.125846C11.6815 -0.0421 11.3869 -0.041666 11.2055 0.126714L5.99998 4.9594L0.794435 0.12654C0.613029 -0.0418184 0.318632 -0.0422525 0.136757 0.125672C0.0455856 0.209928 -1.40071e-08 0.320308 -1.8834e-08 0.430688C-2.36486e-08 0.540786 0.0452804 0.650733 0.135819 0.734836L5.67068 5.87341C5.75782 5.9545 5.87642 6 5.99998 6C6.12354 6 6.24199 5.95437 6.3293 5.87341Z" fill="#24ABE2"/>
          </svg>
        </button>
      </div>
    </div>
    <!--<b-collapse accordion="product-accordion" :id="'product-'+id" v-model="isVisible">
      <div class="products-item__body">
        <div class="products-item__products">
          <div class="products-item__product">
            <div class="products-item__product-img">
              <img src="@/assets/img/product-img.png" alt="">
            </div>
            <div class="products-item__product-info">
              <div class="products-item__product-info-item">
                <div class="products-item__product-info-item-title">Позиция</div>
                <div class="products-item__product-info-item-value">1</div>
              </div>
              <div class="products-item__product-info-item">
                <div class="products-item__product-info-item-title">Заказано</div>
                <div class="products-item__product-info-item-value">15</div>
              </div>
              <div class="products-item__product-info-item">
                <div class="products-item__product-info-item-title">В заказ</div>
                <div class="products-item__product-info-item-value">14</div>
              </div>
              <div class="products-item__product-info-item">
                <div class="products-item__product-info-item-title">Корректировка</div>
                <div class="products-item__product-info-item-value">2</div>
              </div>
              <div class="products-item__product-info-item">
                <div class="products-item__product-info-item-title">Получено</div>
                <div class="products-item__product-info-item-value">12</div>
              </div>
              <div class="products-item__product-info-item">
                <div class="products-item__product-info-item-title">Ед.</div>
                <div class="products-item__product-info-item-value">шт.</div>
              </div>
              <div class="products-item__product-info-item">
                <div class="products-item__product-info-item-title">Цена</div>
                <div class="products-item__product-info-item-value">100 ₴</div>
              </div>
              <div class="products-item__product-info-item">
                <div class="products-item__product-info-item-title">Сумма</div>
                <div class="products-item__product-info-item-value">10 000 ₴</div>
              </div>
            </div>
            <div class="products-item__product-btns-group">
              <a href="" class="btn btn--blue">Вернуть</a>
              <a href="" class="btn btn--blue">В корзину</a>
            </div>
          </div>

        </div>
        <div class="products-item__footer">
          <a href="" class="btn btn--blue">Излишек/Недостача</a>
          <a href="" class="btn btn--blue">Оставить отзыв</a>
          <a href="" class="btn btn--blue">Вернуть товар</a>
          <a href="" class="btn btn--blue">Скачать</a>
          <button v-b-toggle="'product-'+id" class="products-item__arr">
            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.3293 5.87341L11.8643 0.734858C12.0455 0.566499 12.0452 0.293923 11.8634 0.125846C11.6815 -0.0421 11.3869 -0.041666 11.2055 0.126714L5.99998 4.9594L0.794435 0.12654C0.613029 -0.0418184 0.318632 -0.0422525 0.136757 0.125672C0.0455856 0.209928 -1.40071e-08 0.320308 -1.8834e-08 0.430688C-2.36486e-08 0.540786 0.0452804 0.650733 0.135819 0.734836L5.67068 5.87341C5.75782 5.9545 5.87642 6 5.99998 6C6.12354 6 6.24199 5.95437 6.3293 5.87341Z" fill="#24ABE2"/>
            </svg>
          </button>
        </div>
      </div>
    </b-collapse>-->
  </div>
</template>
<script>
import { ref } from '@vue/runtime-core'
export default {
  setup () {
    const id = 0
    const isVisible = ref(false)
    return {
      id,
      isVisible
    }
  }
}
</script>
